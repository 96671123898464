<template>
  <article class="ma-4 anonedepoi">
    <div class="quad_depoimento">
      <div class="nup">
        <img :src="img" alt="" class="imgdepo" />
        <h5 class="pt-6 px-4 butlerbold" align="left">{{ title }}</h5>
        <v-card-text class="text-justify" v-html="text"></v-card-text>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: ["text", "img", "title"],
  computed: {
    time() {
      return new Intl.DateTimeFormat("pt-BR").format(new Date(this.date));
    },
  },
};
</script>

<style>
.imgdepo {
  width: 73px !important;
  height: 73px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: -18% !important;
  left: 30px !important;
  z-index: 9999999;
  object-fit: cover;
}
.anonedepoi {
  padding: 35px 0;
}
.quad_depoimento {
  display: inline-block;
  width: 100%;
  background: #fff;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 4px 10px #ddd;
  min-height: 260px;
  padding: 15px;
}
.nup {
  text-decoration: none;
}
</style>
