<template>
  <div>
    <div
      class="fdtop"
      data-aos="fade-down"
      data-aos-offset="500"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      <v-container class="capa-wrap">
        <v-app-bar-nav-icon
          color="white"
          @click="drawer = !drawer"
          class="d-block d-sm-none"
        ></v-app-bar-nav-icon>
        <v-navigation-drawer v-model="drawer" fixed temporary>
          <v-subheader>MENU</v-subheader>
          <v-list-item-group>
            <v-list dense>
              <v-list-item
                v-for="(route, i) in menu"
                :key="i"
                @click="$vuetify.goTo(route.path)"
                link
              >
                <v-list-item-icon>
                  <v-icon>{{ route.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ route.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-navigation-drawer>
        <div class="capa">
          <v-row>
            <div
              class="col-12 col-sm-12 col-md-6 px-4 py-0 fleft"
              align="center"
            >
              <router-link class="h-100 white--text" to="/">
                <img
                  class="logohome"
                  src="/img/logo.svg"
                  contain
                  height="100%"
                />
              </router-link>
              <h2
                class="uppercase lh53 pt-0 pt-sm-8 pt-md-2 fleft"
                align="center"
              >
                <strong class="butlerbold fs2 lightblue1">{{
                  about.title
                }}</strong>
              </h2>
              <h5
                class="minih5 d-none d-sm-block cor-branco"
                align="center"
                v-html="about.textmini"
              ></h5>
            </div>
            <div class="col-12 col-sm-12 col-md-6 px-4 py-0">&nbsp;</div>
          </v-row>
        </div>
      </v-container>
    </div>
    <div
      class="sectionservice"
      id="servicos"
      data-aos="fade-left"
      data-aos-offset="500"
      data-aos-delay="50"
      data-aos-duration="1000"
    >
      <v-container class="capa-wrap">
        <div class="capa" align="center">
          <img
            class="d-none d-sm-block logo"
            src="/img/icon_logo.svg"
            contain
            height="100%"
          />
          <h2 class="py-4 butlerbold">SERVIÇOS</h2>
          <h5 class="d-none d-sm-block py-4 mw500">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </h5>
          <v-row class="mt-sm-16 mt100">
            <div
              class="col-12 col-sm-4 col-md-4"
              v-for="(e, i) in articles"
              :key="i"
            >
              <div class="nap quad">
                <div class="circleimg">
                  <img
                    class="logo"
                    :src="$store.state.files + '/' + e.img"
                    contain
                    height="100%"
                  />
                </div>
                <h3 class="butlerbold">{{ e.title }}</h3>
                <h5 v-html="e.text"></h5>
              </div>
            </div>
          </v-row>
        </div>
      </v-container>
      <v-container
        class="capa-wrap"
        data-aos="fade-down-right"
        data-aos-offset="500"
        data-aos-delay="50"
        data-aos-duration="1800"
      >
        <div class="capa" align="center">
          <img
            class="d-none d-sm-block logo"
            src="/img/icon_logo.svg"
            contain
            height="100%"
          />
          <h2 class="butlerbold">CONHEÇA</h2>
          <v-row class="my-16">
            <div class="col-12 col-sm-4 col-md-6">
              <img
                class="logo mw100"
                src="/img/about.svg"
                contain
                height="100%"
              />
            </div>
            <div class="col-12 col-sm-4 col-md-6 bgmark">
              <h3 class="butlerbold py-8">Guilherme Barreto</h3>
              <h5 v-html="about.text"></h5>
            </div>
          </v-row>
        </div>
      </v-container>
    </div>
    <v-container class="capa-wrap" id="premiacoes">
      <div
        class="capa"
        align="center"
        data-aos="fade-up-right"
        data-aos-offset="500"
        data-aos-delay="50"
        data-aos-duration="2000"
        data-aos-easing="ease-in-sine"
      >
        <img
          class="d-none d-sm-block logo"
          src="/img/icon_logo.svg"
          contain
          height="100%"
        />
        <h2 class="butlerbold mb-0 b-sm-4">PREMIAÇÕES</h2>
        <div class="py-6 carouselprem" v-if="events.length > 0">
          <carousel
            :items="3"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              600: { items: 1, nav: false, dots: true },
              900: { items: 3, nav: true, dots: false },
            }"
          >
            <news-card
              v-for="(e, i) in events"
              :key="i"
              :img="$store.state.files + '/' + e.cover"
              :caminho="$store.state.files + '/'"
              :imgs="e.imgs"
              :title="e.title"
              :text="e.text"
            ></news-card>
          </carousel>
        </div>
      </div>
    </v-container>
    <div
      class="capa_depoimentos"
      id="depoimentos"
      align="center"
      data-aos="fade-up-left"
      data-aos-offset="500"
      data-aos-delay="250"
      data-aos-duration="2200"
    >
      <div class="capa_depoimentos_bg">
        <v-container class="capa-wrap">
          <div class="capa py-8">
            <img
              class="logo d-none d-sm-block"
              src="/img/icon_logo_filter.png"
              contain
              height="100%"
            />
            <h2 class="butlerbold d-none d-sm-block cor-branco">DEPOIMENTOS</h2>
            <div
              class="py-0 py-sm-6 carouselprem"
              v-if="depoimentos.length > 0"
            >
              <carousel
                :items="3"
                :responsive="{
                  0: { items: 1, nav: false, dots: true },
                  600: { items: 1, nav: false, dots: true },
                  900: { items: 3, nav: true, dots: false },
                }"
              >
                <depoimentos-card
                  v-for="(e, i) in depoimentos"
                  :key="i"
                  :img="$store.state.files + '/' + e.img"
                  :title="e.title"
                  :text="e.text"
                ></depoimentos-card>
              </carousel>
            </div>
          </div>
        </v-container>
      </div>
    </div>
    <div>
      <div class="bgvideoondas py-16" id="videos">
        <v-container class="capa-wrap">
          <div
            class="capa topvideo"
            align="center"
            data-aos="fade-right"
            data-aos-offset="500"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <img
              class="logo d-none d-sm-block"
              src="/img/icon_logo.svg"
              contain
              height="100%"
            />
            <h2 class="d-none d-sm-block butlerbold">VÍDEOS</h2>
          </div>
          <div class="videosowl text-center" v-if="videos.length > 0">
            <carousel
              :items="1"
              :responsive="{
                0: { items: 1, nav: false, dots: true },
                600: { items: 1, nav: false, dots: true },
                900: { items: 1, nav: true, dots: true },
              }"
            >
              <video-display
                v-for="(video, i) in videos"
                :key="i"
                :video="video.video"
              ></video-display>
            </carousel>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import NewsCard from "../components/NewsCard";
import DepoimentosCard from "../components/DepoimentosCard";
import VideoDisplay from "../components/VideoDisplay";

export default {
  data: () => ({
    depositionModel: null,
    nutrologyDialog: false,
    drawer: false,
    menu: [
      { path: "#home", name: "Home", icon: "fas fa-user" },
      { path: "#servicos", name: "Serviços", icon: "fas fa-cog" },
      { path: "#premiacoes", name: "Premiações", icon: "fas fa-trophy" },
      { path: "#depoimentos", name: "Depoimentos", icon: "fas fa-trophy" },
      { path: "#contato", name: "Contato", icon: "fas fa-envelope" },
      { path: "#videos", name: "Vídeos", icon: "fas fa-video" },
    ],
    notifications: false,
    sound: true,
    widgets: false,
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    about() {
      return this.$store.state.about;
    },
    events() {
      return this.$store.state.events;
    },
    depoimentos() {
      return this.$store.state.depoimentos;
    },
    videos() {
      return this.$store.state.videos;
    },
    articles() {
      return this.$store.state.articles;
    },
  },
  beforeCreate() {
    this.$store.dispatch("getAbout");
    this.$store.dispatch("getAllEvents");
    this.$store.dispatch("getAllDepoimentos");
    this.$store.dispatch("getAllVideos");
    this.$store.dispatch("getAllArticles");
  },
  components: {
    carousel,
    NewsCard,
    DepoimentosCard,
    VideoDisplay,
  },
};
</script>

<style
    WhatsappButton>
.lightblue1 {
  color: #d1d4eb !important;
}
.fdtop {
  background: url(/img/Slide.svg) no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 895px;
  display: block;
}
.capa-wrap {
  height: 100%;
}
\ .capa {
  height: auto;
}
.capa > div {
  height: 100%;
  align-items: center;
}
.topvideo {
  padding-top: 10em;
}
.cor-branco {
  color: #ffffff;
}
.uppercase {
  text-transform: uppercase;
}

.sectionservice {
  position: relative;
  background: url(/img/bg_about.svg) no-repeat;
  background-size: contain;
  background-position-y: 35em;
}
.bgvideoondas {
  position: relative;
  background: url(/img/bg_up.png) no-repeat;
  background-size: 100%;
  background-position-y: -6em;
}
.mw500 {
  max-width: 700px;
  margin-bottom: 120px;
}
.mw100 {
  max-width: 100%;
}
.quad {
  text-align: center;
  display: inline-block;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-shadow: 0 1px 13px #f5f5f5;
  margin-bottom: 100px;
}
.circleimg {
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #5d649e;
  height: 172px;
  width: 172px;
  margin-top: -106px;
  background: #ffffff;
  margin-bottom: 20px;
}
.circleimg img {
  max-width: 100px;
  max-height: 100px;
  margin: 34px 0;
  object-fit: cover;
  border-radius: 50%;
}
.bgmark {
  background: url(/img/markgb.png) no-repeat center top;
}
@media (max-width: 1350px) and (min-width: 960px) {
  .fdtop {
    background-size: 100% !important;
    min-height: 645px;
  }
  .lh53 {
    font-size: 1.2em;
  }
  .minih5 {
    font-size: 0.9em;
  }
}
@media (max-width: 960px) {
  body {
    height: 100% !important;
  }
  .capa {
    padding: 0 !important;
  }
  .mw500 {
    max-width: 100%;
  }
  .bgvideoondas {
    background-size: cover;
    background-position-y: -3em;
  }
}
@media (max-width: 460px) {
  .fdtop {
    background-size: 100%;
    background: url(/img/slide_mobile.svg) no-repeat;
    width: 100%;
    min-height: 455px;
  }
  .logohome {
    width: 50%;
    margin-top: 53px;
    margin-left: 20px;
  }
  .fleft {
    text-align: left !important;
  }
  .lh53 {
    font-size: 1em;
  }
  .video-dispay {
    min-height: 220px;
  }
  .mt100 {
    margin-top: 100px !important;
  }
  .capa_depoimentos_bg {
    max-height: 400px !important;
  }
  .butlerbold {
    font-size: 1.5em;
  }
  .fs2 {
    font-size: 1em;
  }
  .sectionservice {
    background-size: initial !important;
    background-position-y: 70em !important;
  }
}
.carouselprem .owl-prev {
  background: none !important;
  width: 34px !important;
}
.carouselprem .owl-next {
  background: none !important;
  width: 34px !important;
}
.carouselprem .owl-prev:before {
  background: url(/img/setas.png) 0px -5px !important;
  content: "";
  width: 25px;
  height: 9px;
  display: inline-block;
}
.carouselprem .owl-next:before {
  background: url(/img/setas.png) 24px -5px !important;
  content: "";
  width: 25px;
  height: 9px;
  display: inline-block;
}
.capa_depoimentos {
  display: inline-block;
  width: 100%;
}
.capa_depoimentos_bg {
  background: rgb(149, 157, 219);
  background: linear-gradient(
    0deg,
    rgb(104 113 181) 0%,
    rgb(56 67 144) 0%,
    rgb(196 200 245) 100%
  );
  max-height: 310px;
}
.videosowl .owl-nav {
  position: absolute;
  top: 45%;
  width: 100%;
}
.videosowl .owl-prev {
  visibility: hidden;
  position: relative;
  position: absolute;
  left: 0;
}
.videosowl .owl-next {
  visibility: hidden;
  position: relative;
  position: absolute;
  right: 0;
}
.videosowl .owl-prev::after {
  content: "";
  background: url(/img/arrowleft.png) no-repeat center #0a1948;
  width: 35px;
  height: 35px;
  position: absolute;
  z-index: 9999999;
  visibility: visible;
  border-radius: 50%;
}
.videosowl .owl-next::after {
  content: "";
  background: url(/img/arrowright.png) no-repeat center #0a1948;
  width: 35px;
  height: 35px;
  position: absolute;
  z-index: 9999999;
  visibility: visible;
  border-radius: 50%;
}
.nap {
  text-decoration: none !important;
}
</style>