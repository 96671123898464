<template>
  <v-footer>
    <div class="container bgf" id="contato">
      <div
        class="capa pt-8 py-2 py-sm-8"
        align="center"
        v-if="$route.path == '/'"
      >
        <img
          class="logo d-none d-sm-block"
          src="/img/icon_logo_filter.png"
          contain
          height="100%"
        />
        <h2 class="butlerbold cor-branco">ENTRE EM CONTATO</h2>
      </div>
      <div class="row py-2 py-sm-10 py-2" v-if="$route.path == '/'">
        <div class="d-none d-sm-block col-12 col-md-1 col-sm-12"></div>

        <div class="d-none d-sm-block col-12 col-md-4 col-sm-12">
          <ul class="d-flex flex-column py-8">
            <li class="mb-2" v-for="(s, i) in social" :key="i">
              <v-btn
                color="cor-cinza2 elevation-0"
                fab
                x-small
                left
                :href="s.link"
                target="_blank"
              >
                <v-icon>{{ s.icon }}</v-icon>
              </v-btn>
              <span class="cor-branco ml-2">{{ s.title }}</span>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-6 col-sm-12">
          <registration-form
            :action="server + '/sendEmail'"
          ></registration-form>
        </div>
        <div class="d-block d-sm-none col-12 col-md-5 col-sm-12">
          <ul class="d-flex flex-column py-8">
            <li class="mb-2" v-for="(s, i) in social" :key="i">
              <v-btn
                color="cor-cinza2 elevation-0"
                fab
                x-small
                left
                :href="s.link"
                target="_blank"
              >
                <v-icon>{{ s.icon }}</v-icon>
              </v-btn>
              <span class="cor-branco ml-2">{{ s.title }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="container text-center">
        <img src="/img/logo_footer.svg" class="py-6" />
      </div>
      <div class="pa-1 orderfooter justify-space-around">
        <ul class="d-flex px-0">
          <v-btn
            class="mx-1 my-2"
            v-for="(s, i) in redsocial"
            :key="i"
            :href="s.link"
            :title="s.link"
            depressed
            fab
            x-small
          >
            <v-icon color="#1a1a1a">{{ s.icon }}</v-icon>
          </v-btn>
        </ul>
        <span class="cor-cinza2 text-center py-3"
          >Copyright © 2020 Guilherme Barreto. Todos os Direitos
          Reservados</span
        >
        <a href="https://www.mrxweb.com.br" target="_blank"
          ><img
            src="/img/mrx.svg"
            alt="MRX Web Sites"
            height="30"
            title="MRX Web Sites"
            class="my-2"
        /></a>
      </div>
    </div>
  </v-footer>
</template>

<script>
import RegistrationForm from "./RegistrationForm.vue";
export default {
  components: { RegistrationForm },
  data: () => ({
    logo: "/img/favicon-white.svg",
  }),
  computed: {
    name() {
      return this.$store.state.holder.name;
    },
    social() {
      return this.$store.state.holder.social;
    },
    redsocial() {
      return this.$store.state.holder.redsocial;
    },
    server() {
      return this.$store.state.server;
    },
  },
};
</script>

<style>
.cor-cinza2 {
  color: #fff;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #e8e8e8 !important;
}
.bgf {
  background: url(/img/Camada_x0020_1.svg) no-repeat top center;
  background-size: contain;
}
.orderfooter {
  display: flex;
}
@media (max-width: 767px) {
  .orderfooter {
    display: grid !important;
    text-align: center;
  }
  .orderfooter .d-flex {
    display: inline-block !important;
  }
}
</style>