<template>
  <router-link :to="to" class="article-card no-link">
    <article class="grey lighten-4">
      <picture v-ripple>
        <img class="filterge" :src="img" :alt="title">
      </picture>
      <h4 class="grey--text text--darken-3 no-link py-2" align="center">{{ title }}</h4>
      <h6 class="grey--text text--darken-1 px-2 no-link mh85 py-2 fw" align="center">{{ txt }}</h6>
    </article>
  </router-link>
</template>

<script>
export default {
  props: ['title', 'to', 'img', 'txt'],
  computed: {
    day() {
      return new Date(this.date).toLocaleDateString('pt-BR', { day: '2-digit' })
    },
    month() {
      return new Date(this.date).toLocaleDateString('pt-BR', { month: 'short' }).toUpperCase().slice(0, -1)
    },
    year() {
      return new Date(this.date).getFullYear()
    }
  },
}
</script>

<style>

.article-card article {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 20px 0;
  position: relative;
  border: 1px solid #afafaf;
  border-radius: 4px;
}

.article-card h5 {
  white-space: normal;
}
.article-card time {
  position: absolute;
  top: 8px;
  left: -4px;
  width: 60px;
  border-radius: 2px;
  display: grid;
  grid-template-rows: 60px 20px;
  padding-left: 2px;
}
.article-card .article-card-month {
  line-height: 14px !important;
}
.article-card .article-card-day {
  line-height: 18px !important;
}
.mh85{
  min-height:85px;
}
.filterge{
  filter: grayscale(1);
}
.fw{
  font-weight: 300;
}
</style>