<template>
  <div class="loading text-center d-flex justify-center align-center">
    <v-progress-circular :size="50" :color="color || 'amber'" indeterminate></v-progress-circular>
  </div>
</template>

<script>
export default {
  props: ['color']
}
</script>

<style>
  .loading {
    height: 100%;
    width: 100%;
  }
</style>