<template>
  <v-container>
    <h2 class="cor-branco my-2">VÍDEOS</h2>
    <div class="row">
      <div class="col-12 col-sm-8">
        <div class="articleheight">
          <div class="videos-list">
            <template v-if="!videos.length">
              <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
              <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
              <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
            </template>
            <template v-else>
              <video-card v-for="(v, i) in videos" :key="i" :to="'/videos/' + v.link" :date="v.date" :title="v.title" :video="v.video"></video-card>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="posabsolute6">
          <img src="../../public/img/barreto2.png">
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import VideoCard from '../components/VideoCard'

export default {
  computed: {
    videos() {
      return this.$store.state.videos
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllVideos')
  },
  components: {
    VideoCard
  }
}
</script>

<style>
.videos-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 336px);
  justify-content: center;
  gap: 20px;
}
.posabsolute6{
  position: absolute;
  bottom: -5%;
  max-width: 100%;
  right: 0;
}
.posabsolute6 img{
  max-height: 600px;
}
@media (max-width: 767px){
  .posabsolute6{
    display: none;
  }
}
</style>