<template>
  <v-container class="about">
    <template v-if="!about.text">
      <v-skeleton-loader class="my-10 mx-2" type="image" min-width="330px" min-height="200px"></v-skeleton-loader>
      <div>
        <v-skeleton-loader  class="mb-4" type="header"></v-skeleton-loader>
        <v-skeleton-loader  class="mb-4" type="chip"></v-skeleton-loader>
        <v-skeleton-loader  class="mb-4" type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader  class="mb-4" type="paragraph"></v-skeleton-loader>
      </div>
    </template>
    <template v-else>
      <img :src="files + about.img">
      <v-sheet>
        <v-card-title class="headline secondary--text">{{ about.title }}</v-card-title>
        <v-card-subtitle class="subtitle-1 primary--text">{{ about.office }}</v-card-subtitle>
        <v-card-text class="text-justify" v-html="about.text"></v-card-text>
      </v-sheet>
    </template>
  </v-container>
</template>

<script>
export default {
  computed: {
    files() {
      return this.$store.state.files + '/'
    },
    about() {
      return this.$store.state.about
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAbout')
  }
}
</script>