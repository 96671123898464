<template>
  <v-container>
    <h2 class="cor-branco my-2">VÍDEOS</h2>
    <template v-if="!video">
      <v-skeleton-loader class="mb-4" type="heading"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="chip"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="image"></v-skeleton-loader>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12 col-sm-8">
          <h5 class="cor-branco mb-8" align="center">{{ video.title }}</h5>
          <div class="row">
            <div class="col-12">
              <iframe class="video-embed elevation-2" :src="embed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12">
              <div class="white--text" v-html="video.text"></div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="posabsolute6">
            <img src="../../public/img/barreto2.png">
          </div>
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    video: null
  }),
  computed: {
    embed() {
      const url = new URL(this.video.video)
      let code

      switch(url.host) {
        case 'www.youtube.com':
          code = url.searchParams.get('v')
        break;
        case 'youtu.be':
          code = url.pathname.replace(/\/(.+)\/?.*/, '$1')
        break;
      }
  
      return 'https://www.youtube.com/embed/' + code;
    }
  },
  mounted() {
    this.$store.dispatch('getAllVideos').then(() => {
      const videos = this.$store.state.videos.filter(v => v.link === this.$route.params.video)

      if(!videos.length) this.$router.push('/error404')

      this.video = videos[0]
    })
  }
}
</script>

<style>
.video-embed {
  max-width: 800px;
  width: 100%;
  min-height: 200px;
}

@media screen and (min-width: 600.001px) and (max-width: 960px) {
  .video-embed {
    min-height: 300px;
  }
}

@media screen and (min-width: 960.001px) {
  .video-embed {
    min-height: 350px;
  }
}
</style>