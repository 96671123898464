<template>
  <v-container>
  <section class="events-list">
    <h2 class="py-2 cor-branco mb-4 gotham text-center">PREMIAÇÕES</h2>
    <div class="row">
      <div class="col-12 col-sm-8">
        <div class="articleheight">
          <div class="list">
            <loading color="white" v-if="!events.length"></loading>
            <template v-else>
              <event-card v-for="(e, i) in events" :key="i" :href="$route.path + '/' + e.link" :img="$store.state.files + '/' + e.cover" :date="e.date" :local="e.local">{{ e.title }}</event-card>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="posabsolute2">
          <img src="../../public/img/barreto2.png">
        </div>
      </div>
    </div>

    <div class="text-center mt-4">
      <!-- <v-pagination v-model="page" :length="Math.ceil(quant / 18)" :total-visible="5" next-icon="fas fa-chevron-right" prev-icon="fas fa-chevron-left"></v-pagination> -->
    </div>
  </section>
  </v-container>
</template>

<script>
import EventCard from '../components/EventCard'
import Loading from '../components/Loading'

export default {
  data: () => ({
    page: 1
  }),
  computed: {
    events() {
      return this.$store.state.events
      // return this.$store.state.events.slice((this.page - 1) * 17, this.page * 18)
    },
    quant() {
      return this.$store.state.quant
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllEvents')
  },
  components: {
    EventCard,
    Loading
  }
}
</script>

<style>
.posabsolute2{
  position: absolute;
  bottom: -5%;
  max-width: 100%;
  right: 0;
}
@media (max-width: 767px){
  .posabsolute2{
    display: none;
  }
}
.events-list .list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: 190px;
  gap: 20px;
  justify-content: center;
}

button.v-pagination__navigation i {
  font-size: 14px !important;
}
</style>