<template>
  <router-link :to="to" class="video-card">
    <article class="elevation-1" v-ripple>
      <img :src="img" :alt="title">
      <div class="legend">
        <time :datetime="date">{{ time }}</time>
        <h5>{{ title }}</h5>
      </div>
    </article>
  </router-link>
</template>

<script>
export default {
  props: ['title', 'date', 'to', 'video'],
  computed: {
    time() {
      return new Intl.DateTimeFormat('pt-BR').format(new Date(this.date))
    },
    img() {
      const url = new URL(this.video)
      let code

      switch(url.host) {
        case 'www.youtube.com':
          code = url.searchParams.get('v')
        break;
        case 'youtu.be':
          code = url.pathname.replace(/\/(.+)\/?.*/, '$1')
        break;
      }
  
      return `https://img.youtube.com/vi/${code}/0.jpg`;
    }
  },
  mounted() {
    this.$el.style.height  = this.$el.clientWidth * 0.75 + 'px'
  }
}
</script>

<style>
.video-card article {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  background: #fff;
  position: relative;
}

.video-card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video-card h5 {
  white-space: normal;
}
.video-card .legend {
  position: absolute;
  border-top: 1px solid rgba(0,0,0,.02);
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 4px 8px;
  background-color: rgba(0,0,0,.4);
  color: #fff;
}
</style>