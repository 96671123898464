<template>
  <router-link :to="href" class="event-card elevation-4" v-ripple>
    <article>
      <img :src="img" alt="">
      <div class="legend">
        <span>{{ local }}</span><time :datetime="date">{{ time }}</time>
        <h4><slot></slot></h4>
      </div>
    </article>
  </router-link>
</template>

<script>
export default {
  props: ['href', 'img', 'date', 'local'],
  computed: {
    time() {
      return new Intl.DateTimeFormat('pt-BR').format(new Date(this.date))
    }
  }
}
</script>

<style>
.event-card article {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: white;
  position: relative;
  border-radius: 4px;
}
.event-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.event-card .legend {
  position: absolute;
  border-top: 1px solid rgba(0,0,0,0.02);
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
.event-card span, .event-card span {
  font-size: 14px;
}
.event-card h4 {
  font-size: 15px;;
}
.event-card time::before {
  content: "\2027";
  padding: 0 2px;
}
</style>
