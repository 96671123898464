<template>
  <v-container>
    <h2 class="cor-azul my-2">Serviços</h2>
    <template v-if="!article">
      <v-skeleton-loader class="mb-4" type="heading"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="chip"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="image"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
    </template>
    <div class="mb-8 row" v v-else>
      <div class="col-12 col-sm-8">
        <div class="articleheight">
          <h5 v-if="article.title" class="cor-azul mb-8" align="center">
            {{ article.title }}
          </h5>
          <div class="" align="center">
            <img class="my-4" :src="files + article.img" :alt="article.title" />
          </div>
          <div class="cor-azul" v-html="article.text"></div>
          <div class="cor-azul py-2" v-html="article.text2"></div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="posabsolute2">
          <img src="../../public/img/barreto1.png" />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    article: null,
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    time() {
      return new Intl.DateTimeFormat("pt-BR").format(
        new Date(this.article.date)
      );
    },
  },
  mounted() {
    this.$store.dispatch("getAllArticles").then(() => {
      const articles = this.$store.state.articles.filter(
        (v) => v.link === this.$route.params.article
      );

      if (!articles.length) this.$router.push("/error404");

      this.article = articles[0];
    });
  },
};
</script>

<style>
.posabsolute2 {
  position: absolute;
  bottom: -5%;
  max-width: 100%;
  right: 0;
}
.article-post {
  display: grid;
  grid-template:
    "title title title"
    "time  time  ."
    ".     img   ."
    "text  text  text"/ 1fr auto 1fr;
  align-content: start;
}
.article-post > h4 {
  grid-area: title;
}
.article-post > time {
  grid-area: time;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.article-post > img {
  grid-area: img;
  border-radius: 4px;
  max-height: 400px;
  max-width: 100%;
}
.article-post > div {
  grid-area: text;
  word-break: break-word;
}
.article .article-list {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
}
.cor-azul {
  color: #040d27;
}
</style>