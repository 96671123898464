<template>
  <v-container>
    <h2 class="cor-azul my-2">Serviços</h2>
    <h5 class="cor-azul mb-8" align="center">Conheça nossos serviços</h5>
    <div class="">
      <template v-if="!articles.length">
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-12 col-sm-9">
            <div class="articleheight">
              <div class="articles-list">
                <article-card
                  v-for="(article, i) in articles"
                  :key="i"
                  :to="'/servicos/' + article.link"
                  :txt="article.text"
                  :img="files + article.img"
                  :title="article.title"
                ></article-card>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3">
            <div class="posabsolute3">
              <img src="../../public/img/barreto1.png" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </v-container>
</template>

<script>
import ArticleCard from "../components/ArticleCard";

export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    articles() {
      return this.$store.state.articles;
    },
  },
  beforeCreate() {
    this.$store.dispatch("getAllArticles");
  },
  components: {
    ArticleCard,
  },
};
</script>

<style>
.articleheight {
  height: 520px;
  overflow-y: auto;
}
.relative {
  position: relative;
}
.posabsolute3 {
  position: absolute;
  bottom: -5%;
  max-width: 100%;
  right: 0;
}

@media (max-width: 767px) {
  .posabsolute3 {
    display: none;
  }
}
.cor-azul {
  color: #040d27;
}
.articles-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 336px);
  grid-gap: 20px;
  justify-content: center;
}
</style>