<template>
  <v-container class="event-post">
    <h2 class="cor-azul my-2">PREMIAÇÕES</h2>
    <div class="row">
      <div class="col-12 col-sm-8">
        <div class="articleheight">
          <h5 v-if="event.title" class="cor-azul mb-8 text-center">
            {{ event.title }}
          </h5>
          <p>
            <span>{{ event.local }}</span>
          </p>
          <gallery v-if="images.length" :imgs="images" :files="files"></gallery>
          <v-btn
            v-if="shareable"
            fixed
            dark
            fab
            bottom
            right
            color="amber darken-1"
            @click="share"
          >
            <v-icon color="white">fas fa-share-alt</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="posabsolute2">
          <img src="../../public/img/barreto2.png" />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Gallery from "../components/Gallery";

export default {
  data: () => ({
    event: {},
    time: "",
    images: [],
    shareable: Boolean(navigator.share),
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
  },
  methods: {
    share() {
      navigator.share({
        title: event.title,
        url: location.href,
      });
    },
  },
  mounted() {
    this.$store.dispatch("getAllEvents").then(() => {
      const events = this.$store.state.events.filter(
        (v) => v.link === this.$route.params.premiacao
      );

      if (!events.length) this.$router.push("/error404");

      this.event = events[0];

      this.images = this.event.imgs.split(",");
    });
  },
  components: {
    Gallery,
  },
};
</script>

<style>
.event-post img {
  border-radius: 4px;
  max-width: 100%;
}

.event-post > p span,
.event-post > p time {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.event-post > p time::before {
  content: "\2027";
  padding: 0 2px;
}
</style>