<template>
  <iframe
    class="video-dispay elevation-2"
    :src="img"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</template>

<script>
export default {
  props: ["video"],
  computed: {
    img() {
      const url = new URL(this.video);
      let code;

      switch (url.host) {
        case "www.youtube.com":
          code = url.searchParams.get("v");
          break;
        case "youtu.be":
          code = url.pathname.replace(/\/(.+)\/?.*/, "$1");
          break;
      }
      return `https://www.youtube.com/embed/` + code;
    },
  },
  mounted() {
    this.$el.style.height = this.$el.clientWidth * 0.75 + "px";
  },
};
</script>

<style>
.video-display article {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  background: #fff;
  position: relative;
}

.video-display img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video-display h5 {
  white-space: normal;
}
.video-display .legend {
  position: absolute;
  border-top: 1px solid rgba(0, 0, 0, 0.02);
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}
.video-dispay {
  max-width: 800px;
  width: 100%;
  min-height: 420px;
}
</style>