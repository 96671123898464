<template>
  <article class="ma-4 anone">
    <div @click="dialog = true" class="cursor-pointer" v-ripple>
      <img :src="img" alt="" class="imgeven" />
      <h5 class="py-4 butlerbold" align="center">{{ title }}</h5>
      <v-card-text class="text-center" v-html="text1"></v-card-text>
    </div>

    <v-dialog v-model="dialog" persistent max-width="900px">
      <v-card>
        <v-toolbar dark color="#011040">
          <v-btn icon dark @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-list-item>
            <v-list-item-content>
              <v-card-text v-html="text"></v-card-text>
              <gallery
                v-if="images.length"
                :imgs="images"
                :files="caminho"
              ></gallery>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </article>
</template>

<script>
import Gallery from "../components/Gallery";

export default {
  props: ["text", "img", "title", "caminho", "imgs"],
  data: () => ({
    dialog: false,
    images: [],
  }),

  computed: {
    time() {
      return new Intl.DateTimeFormat("pt-BR").format(new Date(this.date));
    },
    text1() {
      return this.text && this.text.slice(0, 80);
    },
  },
  mounted() {
    this.images = this.imgs.split(",");
  },
  components: {
    Gallery,
  },
};
</script>

<style>
.imgeven {
  object-fit: cover;
  height: 220px;
}
.anone a {
  text-decoration: none !important;
}
.anone img {
  border-radius: 10px;
}
</style>
