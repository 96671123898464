<template>
  <div class="contact">
    <v-container>
      <h2 class=" my-2 cor-branco">Contato</h2>
      <div>
        <div class="d-flex flex-column flex-md-row justify-center">
          <registration-form class="mb-4 mb-md-0 py-8" :action="server + '/sendEmail'"></registration-form>
          <ul class="d-flex flex-column py-8">
            <li class="mb-2" v-for="(s, i) in social" :key="i">
              <v-btn color="cor-cinza2 elevation-0" fab x-small left :href="s.link" target="_blank">
                <v-icon>{{ s.icon }}</v-icon>
              </v-btn>
              <span class="cor-branco  ml-2">{{ s.title }}</span>
            </li>
          </ul>
        </div>
        <div class="posabsolute5">
          <img src="../../public/img/barreto1.png">
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import RegistrationForm from '../components/RegistrationForm'

export default {
  computed: {
    server() {
      return this.$store.state.server
    },
    social() {
      return this.$store.state.holder.social
    },
    map() {
      return this.$store.state.holder.map
    }
  },
  components: {
    RegistrationForm
  }
}
</script>

<style>
.contacts ul {
  padding-left: 0 !important;
  list-style: none;
}
.posabsolute5 img{
  max-height: 550px;
}
.posabsolute5{
  position: absolute;
  bottom: -5%;
  max-width: 100%;
  right: 0;
}
@media (max-width: 1300px){
  .posabsolute5{
    display: none;
  }
}
</style>