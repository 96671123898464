<template>
    <article>
      <div class="row">
        <div class="col-12 col-sm-1">&nbsp;</div>
        <div class="col-12 col-sm-4" align="center">
          <img :src="img" class="br50" alt="">
          <h5 align="center" class="cor-branco">{{ title }}</h5>
          <h6 align="center" class="cor-branco">{{ cargo }}</h6>
        </div>
        <div class="col-12 col-sm-6">
          <v-card-text class="b box-dep text-justify" v-html="text"></v-card-text>
        </div>
        <div class="col-12 col-sm-1">&nbsp;</div>
      </div>
    </article>
</template>

<script>
export default {
  props: ['text', 'img', 'title', 'cargo'],
  computed: {
    time() {
      return new Intl.DateTimeFormat('pt-BR').format(new Date(this.date))
    }
  }
}
</script>

<style>
.event-card article {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: white;
  position: relative;
  border-radius: 4px;
}
.event-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.event-card .legend {
  position: absolute;
  border-top: 1px solid rgba(0,0,0,0.02);
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
.event-card span, .event-card span {
  font-size: 14px;
}
.event-card h4 {
  font-size: 15px;;
}
.event-card time::before {
  content: "\2027";
  padding: 0 2px;
}
.br50{
  border-radius: 50%;
  width: 180px !important;
  height: 180px;
  border: 4px solid #8383a0;
  object-fit: cover;
  display: inline-block !important;
}
.box-dep{
  padding: 10px;
  border: 4px solid #8383a0;
  background: #ffffffb5;
  color: #090d26;
  position: relative;
}
.box-dep:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;
  position: absolute;
  left: -28px;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 25px solid #8383a0;
  top: -5px;
}
</style>
