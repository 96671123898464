import Vue from 'vue'
import Vuex from 'vuex'
import RPC from './rpc'

Vue.use(Vuex)

const server = 'https://v2.guilhermebarreto.com.br'

const holder = {
  namespaced: true,
  state: {
    name: 'Guilherme Barreto',
    social: [
      {
        link: 'https://api.whatsapp.com/send?phone=5566996615052&text=Ol%C3%A1%2C%20acessei%20seu%20site%20e%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os.',
        title: '(66) 99661-5052',
        icon: 'fab fa-whatsapp'
      },
      {
        link: 'tel:553196989151',
        title: 'Telefone: +55 31 9698-9151',
        icon: 'fas fa-phone'
      },
      {
        link: 'mailto:contato@guilhermebarreto.com.br',
        title: 'contato@guilhermebarreto.com.br',
        icon: 'fas fa-envelope'
      },
      // {
      //   link: 'https://www.facebook.com/gbarreto/',
      //   title: 'Facebook @gbarreto',
      //   icon: 'fab fa-facebook'
      // },
      // {
      //   link: 'https://www.instagram.com/fernandaquinelatonutrologia/',
      //   title: 'Instagram @gbarreto',
      //   icon: 'fab fa-instagram'
      // },

    ],
    redsocial: [
      {
        link: 'https://www.facebook.com/guilhermebarreto18/',
        title: 'Instagram',
        icon: 'fa fa-facebook'
      },
      {
        link: 'https://www.instagram.com/guilhermebarreto18/',
        title: 'Instagram',
        icon: 'fa fa-instagram'
      },
    ],
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3904.682042415693!2d-55.513645385189314!3d-11.85751549159352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93a77ff48fb68e7f%3A0xad070bfe26156832!2sAv.%20das%20Ita%C3%BAbas%2C%203101%20-%20St.%20Comercial%2C%20Sinop%20-%20MT%2C%2078898-000!5e0!3m2!1spt-BR!2sbr!4v1588643991670!5m2!1spt-BR!2sbr'
  }
}

export default new Vuex.Store({
  state: {
    files: server,
    server: server,
    articles: [],
    depositions: [],
    about: {},
    nutrology: {},
    events: [],
    slides: [],
    depoimentos: [],
    videos: []
  },
  ...RPC(server, {
    getAllArticles: 'articles',
    getAllDepositions: 'depositions',
    getAbout: 'about',
    getNutrology: 'nutrology',
    getAllSlides: 'slides',
    getAllEvents: 'events',
    getAllVideos: 'videos',
    getAllDepoimentos: 'depoimentos'
  }),
  modules: {
    holder
  }
})
