<template>
  <v-app class="sfd">
    <main-header v-if="$route.path !== '/'"></main-header>

    <v-content>
      <router-view></router-view>
    </v-content>

    <main-footer class="corcinzaescuro"></main-footer>
    <whatsapp-button
      class="dg"
      :tel="'5566996615052'"
      :text="'Olá acessei ao seu site e gostaria de saber mais sobre seus serviços.'"
    ></whatsapp-button>
  </v-app>
</template>

<script>
import MainHeader from "./components/MainHeader";
import MainFooter from "./components/MainFooter";
import WhatsappButton from "./components/WhatsappButton";

export default {
  components: {
    MainHeader,
    MainFooter,
    WhatsappButton,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import "../public/css/animate.css";
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Montserrat", sans-serif;
}
html {
  font-size: 16px;
}
main {
  font-size: 0.875rem;
  letter-spacing: 0.25px;
}
ul {
  list-style: none;
  padding: 0;
}
h1 {
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: 0px;
}
h2 {
  font-size: 2.125rem;
  font-weight: bold;
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
}
h3 {
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 12px;
}
h3.primary--text::after {
  background: var(--v-primary-base);
}
h3.white--text::after {
  background: white;
}
h4 {
  font-size: 1.25rem;
  font-weight: medium;
  letter-spacing: 0.15px;
}
h5 {
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.15px;
}
h6 {
  font-size: 0.875rem;
  font-weight: medium;
  letter-spacing: 0.1px;
}
.sfd {
  background: none !important;
}

.bgbran {
  background: #ffffff;
}
.bgtransp {
  background: none !important;
}

.h-100 {
  height: 100% !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.no-link,
.no-link:active,
.no-link:link {
  text-decoration: none;
  color: inherit;
}
.corcinzaescuro {
  background-color: #0b1039 !important;
  background-image: linear-gradient(
    90deg,
    rgb(10 25 72) 0%,
    rgb(3 11 35) 0%,
    rgb(20 40 103) 100%
  );
}
.fdbra {
  background: #ffffff;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 2px 14px #6886ff;
}
@font-face {
  font-family: "butlerbold";
  src: url("./fonts/butler/butler_bold-webfont.woff2") format("woff2"),
    url("./fonts/butler/butler_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.butlerbold {
  font-family: "butlerbold";
  color: #2a316b;
}
.cursor-pointer {
  cursor: pointer;
}
.dg {
  position: fixed;
}
</style>
