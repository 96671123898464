<template>
  <v-container>
  <section class="depoimentos-list">
    <h2 class="py-2 cor-branco mb-4 gotham text-center">DEPOIMENTOS</h2>
    <div class="row">
      <div class="col-12 col-sm-8">
        <div class="list">
          <loading color="white" v-if="!depoimentos.length"></loading>
          <template v-else>
            <div class="py-6" v-if="depoimentos.length > 0">
              <carousel :items="1" :nav="false" :responsive="{0: { items: 1, nav: false },600: { items: 1, nav: false },}">
                <depoimento-card v-for="(e, i) in depoimentos" :key="i" :img="$store.state.files + '/' + e.img" :cargo="e.cargo" :title="e.title" :text="e.text"></depoimento-card>
              </carousel>
            </div>
          </template>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="posabsolute4">
          <img src="../../public/img/barreto3.png">
        </div>
      </div>
    </div>
    <div class="text-center mt-4">
      <!-- <v-pagination v-model="page" :length="Math.ceil(quant / 18)" :total-visible="5" next-icon="fas fa-chevron-right" prev-icon="fas fa-chevron-left"></v-pagination> -->
    </div>
  </section>
  </v-container>
</template>

<script>
import DepoimentoCard from '../components/DepoimentoCard'
import Loading from '../components/Loading'
import carousel from 'vue-owl-carousel'

export default {
  data: () => ({
    page: 1
  }),
  computed: {
    depoimentos() {
      return this.$store.state.depoimentos
      // return this.$store.state.depoimentos.slice((this.page - 1) * 17, this.page * 18)
    },
    quant() {
      return this.$store.state.quant
    }
  },
  beforeCreate() {
    this.$store.dispatch('getAllDepoimentos')
  },
  components: {
    DepoimentoCard,
    carousel,
    Loading
  }
}
</script>

<style>
.depoimentos-list .list {
  display: inline-block;
  justify-content: center;
  width: 100%;
}
.posabsolute4{
  position: absolute;
  bottom: -5%;
  max-width: 100%;
  right: 0;
}
.posabsolute4 img{
  max-height: 550px;
}
@media (max-width: 767px){
  .posabsolute4{
    display: none;
  }
}
button.v-pagination__navigation i {
  font-size: 14px !important;
}
</style>