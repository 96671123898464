<template>
  <div class="bgazul">
    <v-container
      v-if="$vuetify.breakpoint.mdAndUp"
      fluid
      :class="[$route.path !== '/' ? 'transparent' : 'absolute']"
    >
      <v-container class="header mdAndUp py-1">
        <router-link class="white--text h-100" to="/">
          <img class="logo mx-10" src="/img/logo.png" contain height="100%" />
        </router-link>

        <nav class="ml-auto navpadd" align="center">
          <v-btn
            :class="[current === route.path ? 'active' : '', 'white--text']"
            v-for="(route, i) in menu2"
            :key="i"
            :to="route.path"
            text
          >
            {{ route.name }}
          </v-btn>
        </nav>
      </v-container>
    </v-container>

    <template v-else>
      <v-app-bar
        fixed
        color="primary"
        :height="$route.path === '/' ? '100px' : ''"
        app
      >
        <router-link class="h-100 white--text" to="/">
          <img class="logointerna" src="/img/logo.png" contain height="100%" />
        </router-link>

        <v-spacer></v-spacer>
      </v-app-bar>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu2: [{ path: "/", name: "Home", icon: "fas fa-user" }],
      current: this.$route.path,
    };
  },
  computed: {
    social() {
      return this.$store.state.holder.social;
    },
  },
};
</script>

<style scoped>
.bgazul {
  background-color: #0b1039 !important;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgb(10 25 72)),
    color-stop(0%, rgb(3 11 35)),
    to(rgb(20 40 103))
  );
  background-image: linear-gradient(
    90deg,
    rgb(10 25 72) 0%,
    rgb(3 11 35) 0%,
    rgb(20 40 103) 100%
  );
}
.absolute {
  position: absolute;
  z-index: 100;
}
.header.mdAndUp {
  width: 100%;
  display: grid;
  grid-template: "logo social";
  align-items: center;
}
.header.mdAndUp > :nth-child(1) {
  grid-area: logo;
}
.header.mdAndUp > :nth-child(2) {
  grid-area: social;
}
.header.mdAndUp > :nth-child(3) {
  grid-area: nav;
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
}
.logo {
  height: 100%;
  object-fit: contain;
  max-width: 400px;
  width: 100%;
}
.logointerna {
  height: 100%;
  object-fit: contain;
  max-width: 400px;
  width: 100%;
}
.navpadd a {
  padding: 0 10px !important;
  text-transform: inherit;
  font-weight: 300;
}
/* .nav.mdAndUp a {
  font-weight: bold;
  text-decoration: none;
  font-size: 1rem;
  white-space: nowrap;
  color: white;
  transition: 0.25s;
}
.nav.mdAndUp a:hover {
  color: black;
} */
</style>