import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Articles from '../views/Articles.vue'
import Article from '../views/Article.vue'
import Contact from '../views/Contact.vue'
import Events from '../views/Events.vue'
import Event from '../views/Event.vue'
import Videos from '../views/Videos.vue'
import Video from '../views/Video.vue'
import Depoimentos from '../views/Depoimentos.vue'
import Error404 from '../views/Error404.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Guilherme Barreto',
    component: Home
  },
  {
    path: '/sobre',
    name: 'Sobre - Guilherme Barreto',
    component: About
  },
  {
    path: '/servicos',
    name: 'Serviços - Guilherme Barreto',
    component: Articles
  },
  {
    path: '/servicos/:article',
    name: 'Serviços - Guilherme Barreto',
    component: Article
  },

  {
    path: '/premiacoes',
    name: 'Premiações - Guilherme Barreto',
    component: Events
  },
  {
    path: '/premiacoes/:premiacao',
    name: 'Premiações - Guilherme Barreto',
    component: Event
  },

  {
    path: '/videos',
    name: 'Vídeos - Guilherme Barreto',
    component: Videos
  },
  {
    path: '/videos/:video',
    name: 'Vídeo - Guilherme Barreto',
    component: Video
  },
  {
    path: '/contato',
    name: 'Contato - Guilherme Barreto',
    component: Contact
  },
  {
    path: '/depoimentos',
    name: 'Depoimentos - Guilherme Barreto',
    component: Depoimentos
  },
  {
    path: '*',
    name: 'Error404',
    component: Error404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(to => {
  document.title = to.name
  window.scrollTo(0, 0)
})

export default router
