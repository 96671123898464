<template>
  <div class="gallery">
    <v-card
      class="gallery-item"
      v-for="(img, i) in imgs"
      :key="i"
      @click="
        open = true;
        active = i;
      "
    >
      <img class="gallery-img" :src="files + img" />
    </v-card>

    <v-dialog content-class="lightbox-container" v-model="open">
      <v-carousel
        v-model="active"
        hide-delimiters
        next-icon="fas fa-chevron-right"
        prev-icon="fas fa-chevron-left"
      >
        <v-carousel-item v-for="(img, i) in imgs" :key="i">
          <img :src="files + img" />
        </v-carousel-item>
      </v-carousel>
      <v-btn @click="open = false" class="close" color="#f44336" icon>
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    imgs: {
      type: Array,
      default: () => [],
    },
    files: {
      type: String,
    },
  },
  data: () => ({
    active: "",
    open: false,
  }),
};
</script>

<style>
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-rows: 100px;
  justify-content: center;
  gap: 10px;
}
.gallery-item {
  cursor: pointer;
  overflow: hidden;
}
.gallery-item:hover .gallery-img {
  transform: scale(1.1);
}
.gallery-img {
  transition: 0.25s;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.lightbox-container {
  position: relative;
  height: calc((100vw - 48px) * 2 / 3) !important;
}
.lightbox-container .close {
  position: absolute;
  top: -2px;
  right: 0px;
  font-size: 30px;
  cursor: pointer;
}
.lightbox-container img {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}
.lightbox-container div {
  overflow: hidden;
}
.lightbox-container {
  display: flex;
  justify-content: center;
}
.v-carousel.v-item-group,
.v-carousel__item,
.lightbox-container {
  height: max-content !important;
  width: max-content !important;
}
.lightbox-container .fa-chevron-right,
.lightbox-container .fa-chevron-left {
  font-size: 20px !important;
}
</style>