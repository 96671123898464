/**
 * RPC Store Vuex
 * 
 * @constructor
 * @param {{URL}} base
 * @param {{routes}} object
 * @example RPC('localhost', { getAll: 'list' })
 *          $store.dispatch('getAll')
 */

export default function (base, routes) {
  let store = { mutations: {}, actions: {} }
  
  for(let path in routes) {
    if(routes[path])
      store.mutations[path] = (states, data) => states[routes[path]] = data

    store.actions[path] = ({ commit }) =>
      fetch(base + '/' + path)
        .then(r => r.ok ? r.json() : Promise.reject())
          .then(data => routes[path] && commit(path, data))
  }

  return store
}